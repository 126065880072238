


import req from './https'



//  區域

export const apiGetSmokeAreaAll = (params) => req('get', '/Questionnire/GetSmokeAreaAll', params)
export const apiGetSmokeAreaPages = (params) => req('post', '/Questionnire/GetSmokeAreaPages', params)
export const apiAddSmokeArea = (params) => req('post', '/Questionnire/AddSmokeArea', params)
export const apiEditSmokeArea = (params) => req('put', '/Questionnire/EditSmokeArea', params)



//  座位號碼
export const apiGetSeatNoPages = (params) => req('post', '/Questionnire/GetSeatNoPages', params)
export const apiAddSeatNo = (params) => req('post', '/Questionnire/AddSeatNo', params)
export const apiEditSeatNo = (params) => req('put', '/Questionnire/EditSeatNo', params)


//  吸菸區實名制問卷設計
export const apiGetSmokingZoneCnrDesign = (params) => req('get', '/Questionnire/GetSmokingZoneCnrDesign', params)
export const apiSaveSmokingZoneCnrDesign = (params) => req('post', '/Questionnire/SaveSmokingZoneCnrDesign', params)





