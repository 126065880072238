import Vue from 'vue';
import Vuex from 'vuex';

import { apiGetRestaurantCnrDesign } from '@/apis/restaurantManage'
import { apiGetSmokingZoneCnrDesign } from '@/apis/smokingManage'
import { apiGetPersonalHealthQnrDesign } from '@/apis/healthManage'
import { apiGetExternalHealthQnrDesign } from '@/apis/externalQnrManage'


Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		editMenu: false, // 選單變更
        subMenu: [], // 選單(子選單)
        isMini: false,
        isSmOpen: false,
        isOpen: false,
        lang: null,  // 存放使用者選用的語系
        // API 回應資訊
        showResponseData: false,
        isLoading: false,
        watchReportGuid: '',
        restaurantCnrDesign: {},    //  餐廳實名制表單內容
        smokingZoneCnrDesign: {},   //  吸菸實名制表單內容
        personalHealthQnrDesign: {},    //  個人疫調
        externalHealthQnrDesign: {},    //  外部疫調
	},
	mutations: {
		// 切換語系設定
        setLang (state, value) {
            state.lang = value;
        },
        LOADING(state, status) {
            state.isLoading = status;
        },
        // 選單變更
        EDITMENU(state, payload) {
            state.editMenu = payload;
        },
        // 選單(子選單)
        SUBMENU(state, payload) {
            state.subMenu = payload;
        },
        SIDEBARMINI(state, payload) {
            state.isMini = payload;
        },
        SIDEBARSMOPEN(state, payload) {
            state.isSmOpen = payload;
        },
        SIDEBAROPEN(state, payload) {
            state.isOpen = payload;
        },
        // API 回應資訊
        OpenResponseDataModal(state, payload) {
            state.showResponseData = payload;
        },
        WATCHREPORTGUID(state, payload) {
            state.watchReportGuid = payload
        },
        RESTAURANTCNRDESIGN(state, payload) {
            state.restaurantCnrDesign = payload
        },
        SMOKINGZONECNRDESIGN(state, payload) {
            state.smokingZoneCnrDesign = payload
        },
        PERSONALHEALTHQNRDESIGN(state, payload) {
            state.personalHealthQnrDesign = payload
        },
        EXTERNALHEALTHQNRDESIGN(state, payload) {
            state.externalHealthQnrDesign = payload
        }
	},
	actions: {
		sidebaropen({ commit }, payload) {
            commit("SIDEBAROPEN", payload);
        },
        sidebarsmopen({ commit }, payload) {
            commit("SIDEBARSMOPEN", payload);
        },
        sidebarmini({ commit }, payload) {
            commit("SIDEBARMINI", payload);
        },
        updateLoading(context, status) {
            context.commit("LOADING", status);
        },
        updateWatchGuid({commit}, payload) {
            commit("WATCHREPORTGUID", payload)
        },
        // 最新餐廳實名制問卷設計
        getRestaurantCnrDesign({ commit }) {
            
            commit("LOADING", true);
            apiGetRestaurantCnrDesign()
            .then((response) => {
                // console.log(response)

                if(response.status === 200) {
                    commit("RESTAURANTCNRDESIGN", response.data)
                }

                commit("LOADING", false);
            })
            .catch(() => {
                commit("LOADING", false);
            })
        },
        // 最新吸菸區實名制問卷設計
        getSmokingZoneCnrDesign({ commit }) {
            
            commit("LOADING", true);
            apiGetSmokingZoneCnrDesign()
            .then((response) => {
                // console.log(response)

                if(response.status === 200) { 
                    commit("SMOKINGZONECNRDESIGN", response.data)
                }

                commit("LOADING", false);
            })
            .catch(() => {
                commit("LOADING", false);
            })
        },
        //  最新疫調問卷設計
        getPersonalHealthQnrDesign({ commit }) {
            
            commit("LOADING", true);
            apiGetPersonalHealthQnrDesign()
            .then((response) => {
                // console.log(response)

                if(response.status === 200) {
                    commit("PERSONALHEALTHQNRDESIGN", response.data)
                }

                commit("LOADING", false);
            })
            .catch(() => {
                commit("LOADING", false);
            })
        },
        //  最新疫調問卷設計
        getExternalHealthQnrDesign({ commit }) {
            
            commit("LOADING", true);
            apiGetExternalHealthQnrDesign()
            .then((response) => {
                // console.log(response)

                if(response.status === 200) {
                    commit("EXTERNALHEALTHQNRDESIGN", response.data)
                }

                commit("LOADING", false);
            })
            .catch(() => {
                commit("LOADING", false);
            })
        },
	},
	getters: {
		editMenu: (state) => state.editMenu, // 選單變更
		lang: (state) => state.lang,
		showResponseData: (state) => state.showResponseData, // API 回應資訊 Modal
		isLoading: (state) => state.isLoading,
        watchReportGuid: (state) => state.watchReportGuid,
        restaurantCnrDesign: (state) => state.restaurantCnrDesign,
        smokingZoneCnrDesign: (state) => state.smokingZoneCnrDesign,
        personalHealthQnrDesign: (state) => state.personalHealthQnrDesign,
        externalHealthQnrDesign: (state) => state.externalHealthQnrDesign,
	}
});
