


import req from './https'



//  棟別

export const apiGetBulidingAll = (params) => req('get', '/Questionnire/GetBulidingAll', params)
export const apiGetBulidingPages = (params) => req('post', '/Questionnire/GetBulidingPages', params)
export const apiAddBuliding = (params) => req('post', '/Questionnire/AddBuliding', params)
export const apiEditBuliding = (params) => req('put', '/Questionnire/EditBuliding', params)
// export const apiDeleteBuliding = (params) => req('delete', '/Questionnire/DeleteBuliding', params)



//  區域 
export const apiGetAreaPages = (params) => req('post', '/Questionnire/GetAreaPages', params)
export const apiAddArea = (params) => req('post', '/Questionnire/AddArea', params)
export const apiEditArea = (params) => req('put', '/Questionnire/EditArea', params)


//  座位號碼 
export const apiGetRestaurantSeatNoPages = (params) => req('post', '/Questionnire/GetRestaurantSeatNoPages', params)
export const apiAddRestaurantSeatNo = (params) => req('post', '/Questionnire/AddRestaurantSeatNo', params)
export const apiEditRestaurantSeatNo = (params) => req('put', '/Questionnire/EditRestaurantSeatNo', params)


//  儲存餐廳
export const apiGetRestaurantCnrDesign = (params) => req('get', '/Questionnire/GetRestaurantCnrDesign', params)
export const apiSaveRestaurantCnrDesign = (params) => req('post', '/Questionnire/SaveRestaurantCnrDesign', params)





