
import { Notification } from'element-ui'
import router from '@/router/index'


// 錯誤msg消息提示
export const tip = msg => {
    // this.$notify({
    //     title: '提示',
    //     message: '这是一条不会自动关闭的消息',
    //     duration: 0
    // });
    Notification({
        title: '錯誤',
        message: msg,
        type: 'error',
        duration: 3500
    });
}

//  跳轉回首頁，攜帶當前頁面路由，登入完成後跳轉回原本頁面
export const toLogin = () => {
    // router.replace({
    //     // name: 'Login',
    //     path: '/',
    //     // query: {
    //     //     redirect: router.currentRoute.fullPath
    //     // }
    // })
    router.push('/login')
}


// export const to403Page = () => {
//     router.replace({
//         name: "403"
//     })
// }

