
import req from './https'



//  最新疫調問卷
export const apiGetExternalHealthQnrDesign = (params) => req('get', '/ExternalQnr/GetExternalHealthQnrDesign', params)
export const apiSaveExternalHealthQnrDesign = (params) => req('post', '/ExternalQnr/SaveExternalHealthQnrDesign', params)



//  調查項目
export const apiGetSurveyItemPages = (params) => req('post', '/ExternalQnr/GetSurveyItemPages', params)
export const apiAddSurveyItem = (params) => req('post', '/ExternalQnr/AddSurveyItem', params)
export const apiEditSurveyItem = (params) => req('put', '/ExternalQnr/EditSurveyItem', params)
export const apiDeleteSurveyItem = (params) => req('post', '/ExternalQnr/DeleteSurveyItem', params)





//  排序調查項目
export const apiSortSurveyItem = (params) => req('post', '/ExternalQnr/SortSurveyItem', params)

