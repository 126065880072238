

import axios from 'axios'
// import router from '@/router/index'
import { tip, toLogin } from './utils'

import store from '@/store'


const errorHandle = (status, msg) => {
    switch(status) {
        //  400: 登入失敗，可能帳密或密碼錯誤
        case 400:
            tip(msg)
            store.dispatch("updateLoading", false);
            break;

        //  401: backend session過期 => 移到checklogin去判斷
        case 401:
            tip('登入過期，請重新登入')
            setTimeout(() => {
                toLogin()
            }, 1000)
            store.dispatch("updateLoading", false);
            break;

        //  403: 權限不足
        case 403:
            // to403Page()
            tip('您沒有權限，若需提升權限，請聯繫系統管理員。')
            // store.dispatch("updateLoading", false);
            break;

        //  404: 請求失敗
        case 404:
            tip(msg)
            store.dispatch("updateLoading", false);
            break;
        
        //  500: server error
        case 500:
            tip('系統程式發生錯誤，請聯絡程式人員')
            store.dispatch("updateLoading", false);
            break;

        default:
            console.log('res沒有攔截到的錯誤:' + msg);

            store.dispatch("updateLoading", false);
    }
}


//  axios的實例
var instance = axios.create({
    // baseURL: "http://api.yahome-test.com.tw/AseglobalQuestionnaireSystemApi"
    baseURL: window.BaseUrl.api
})

//  request攔截器
instance.interceptors.request.use((config) => {
    config.headers["Content-Type"] = "application/json; charset=utf-8";
    return config
}, (error) => {
    return Promise.reject(error)
})

//  response 攔截器
instance.interceptors.response.use((response) => {
    return response
}, (error) => {
    const { response } = error

    if(response) {
        //  成功發出請求且收到resp，但有error

        if (response.config.responseType == "blob") {
            response.data.text()
                .then(res => {
                    errorHandle(response.status, JSON.parse(res))
                })
        } else {
            errorHandle(response.status, response.data)
        }

        return Promise.reject(error)
    } else {
        //  成功發出請求但沒收到resp
        if(!window.navigator.onLine) {
            //  如果網路斷線
            tip('網路出了點問題，請重新連線後刷新網頁')
        } else {
            //  可能是跨域，或是程式問題
            return Promise.reject(error)
        }
    }
})


export default function (method, url, data=null, config) {
    method = method.toLowerCase()
    if(method === 'post') {
        return instance.post(url, data, config)
    } else if(method === 'get') {
        return instance.get(url, {params: data})
    } else if(method === 'delete') {
        return instance.delete(url, {params: data})
    } else if(method === 'put') {
        return instance.put(url, data)
    } else {
        console.error('未知的method' + method)
        return false
    }
}

