
import req from './https'



//  最新疫調問卷
export const apiGetPersonalHealthQnrDesign = (params) => req('get', '/Questionnire/GetPersonalHealthQnrDesign', params)
export const apiSavePersonalHealthQnrDesign = (params) => req('post', '/Questionnire/SavePersonalHealthQnrDesign', params)



//  調查項目
export const apiGetSurveyItemPages = (params) => req('post', '/Questionnire/GetSurveyItemPages', params)
export const apiAddSurveyItem = (params) => req('post', '/Questionnire/AddSurveyItem', params)
export const apiEditSurveyItem = (params) => req('put', '/Questionnire/EditSurveyItem', params)
export const apiDeleteSurveyItem = (params) => req('post', '/Questionnire/DeleteSurveyItem', params)





//  排序調查項目
export const apiSortSurveyItem = (params) => req('post', '/Questionnire/SortSurveyItem', params)

