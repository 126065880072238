import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import 'fontawesome-4.7/css/font-awesome.min.css';

import 'bootstrap';

import jquery from "jquery";
window.$ = jquery;

axios.defaults.withCredentials = true; // 設定cross跨域並設定訪問許可權，允許跨域攜帶cookie資訊

Vue.use(Element)

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.component('Loading', Loading)

// Table
import VueBootstrap4Table from "vue-bootstrap4-table";
Vue.component("VueBootstrap4Table", VueBootstrap4Table);

// cookies
import VueCookies from "vue-cookies";
Vue.use(VueCookies)

// 捲軸
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar);

// eva icon
import EvaIcons from "vue-eva-icons";

Vue.use(EvaIcons);

// Select (樹狀)
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

Vue.component("Treeselect", Treeselect);

// Select (多選)
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

Vue.component("multiselect", Multiselect);

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);

// 日期
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";

Vue.component("DatePicker", DatePicker);

// HTML編輯器 (範本維護)
import { VueEditor } from "vue2-editor";
Vue.component("VueEditor", VueEditor);

import VueI18n from 'vue-i18n'  // 引入 Vue I18n
import zh from '../public/i18n/zh.json'      // 存放中文語系檔
import en from '../public/i18n/en.json'      // 存放英文語系檔

Vue.use(VueI18n)

// 預設使用的語系
let locale = 'zh';

// 檢查 localStorage 是否已有保存使用者選用的語系資訊
if (localStorage.getItem('footmark-lang')) {
    locale = localStorage.getItem('footmark-lang');
    store.commit('setLang', locale);
} else {
    store.commit('setLang', locale);
}

const i18n = new VueI18n({
    locale: locale,
    messages: {
      'zh': zh,
      'en': en
    }
});

// vee-validate (驗證)
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";

import {
  email,
  required,
  numeric,
  alpha_dash,
  alpha_num,
  alpha_spaces,
  min,
  min_value,
  max,
  max_value,
  is_not,
  excluded,
  confirmed,
  integer,
  regex,
} from "vee-validate/dist/rules";
import EN from "vee-validate/dist/locale/en.json";
import TW from "vee-validate/dist/locale/zh_TW.json";

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);


////// vee-validate (rule) ///////////////////////////////////////////////

localize("EN", EN);
localize("TW", TW);
extend("integer", integer);
extend("min", min);
extend("max", max);
extend("excluded", excluded);
extend("alpha_dash", alpha_dash);
extend("alpha_num", alpha_num);
extend("alpha_spaces", alpha_spaces);
extend("is_not", is_not);
extend("confirmed", confirmed);
extend("regex", regex);
// extend("required", required);

// extend("required", {
//     validate() {
//         // ...
//     },
//     // This rule reports the `required` state of the field.
//     computesRequired: true,
//     });

extend("required", {
    ...required,
    message: (_, values) => i18n.t("vee-validations.Required", values),
    // message: "必填 Required field"
});

extend("password_length", {
    validate: (value, args) => {
        return value.length >= args.length;
    },
    params: ["length"],
    message: "{_field_} 長度不符合",
});

extend("password", {
    params: ["target"],
    validate(value, { target }) {
        return value === target;
    },
    message: "新密碼輸入不符",
});

extend("min_value", {
    ...min_value,
    message: TW.messages.min_value,
    params: ["min"],
});

extend("checkbox", {
    validate: (value) => {
        return value !== false;
    },
    message: "請新增 {_field_}",
});

extend("department", {
    validate: (value, { department }) => {
        return department === [];
    },
    message: "請新增 {_field_}2",
    params: ["department"],
});

extend("max_value", {
    ...max_value,
    message: TW.messages.max_value,
    params: ["max"],
});

extend("my_not", {
    validate: (value, { now }) => {
        return value !== now;
    },
    message: "{_field_} 不可重複",
    params: ["now"],
});

extend("email", {
    ...email,
    message: (_, values) => i18n.t("vee-validations.Email", values),
});

extend("numeric", {
    ...numeric,
    message: (_, values) => i18n.t("vee-validations.Numeric", values),
});

extend("demandApply_PositionValue", {
    // 職務名稱
    validate: (value) => {
        return value.PositionCode !== null;
    },
    message: "{_field_} 為必填",
});

Vue.config.productionTip = false;

new Vue({
    i18n,
    router,
    store,
    render(h) { return h(App); },
}).$mount('#app');


