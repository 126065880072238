import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// const routes = [
//   // {
//   //   path: '/',
//   //   name: 'Home',
//   //   component: Home,
//   // },
// 	// {
// 	// 	path: '/',
// 	// 	name: 'Precautions',
// 	// 	component() {
// 	// 		return import('@/views/Precautions/Precautions.vue');
// 	// 	},
// 	// },
// 	{
// 		path: "/",
// 		name: 'Login',
// 		component: () => import("@/views/back/Login/ManagerLogin"),
// 	},
// 	{
// 		path: '/precautions',
// 		name: 'Precautions',
// 		component() {
// 			return import('@/views/Precautions/Precautions.vue');
// 		},
// 	},
// 	{
// 		path: '/health',
// 		name: 'Health',
// 		component() {
// 			return import('@/views/Questionnaire/Health.vue');
// 		},
// 	},
// 	{
// 		path: '/smoking',
// 		name: 'Smoking',
// 		component() {
// 			return import('@/views/Questionnaire/Smoking.vue');
// 		},
// 	},
// 	{
// 		path: '/restaurant/:id',
// 		name: 'Restaurant',
// 		component() {
// 			return import('@/views/Questionnaire/Restaurant.vue');
// 		},
// 	},
// 	{
// 		path: '/finish',
// 		name: 'Finish',
// 		component() {
// 			return import('@/views/Questionnaire/Finish.vue');
// 		},
// 	},
// 	// {
// 	// 	path: "/login",
// 	// 	name: 'Login',
// 	// 	component: () => import("@/views/back/Login/ManagerLogin"),
// 	// },
// 	{
// 		path: "/manager",
// 		component: () => import("@/views/back/Layout/ManagerLayout"),
// 		meta: { managerAuth: true },
// 		children: [
// 			{
// 				path: "/healthReport",
// 				name: "問卷報表,健康疫調問卷",
// 				component: () => import("@/views/back/Report/Report"),
// 				meta: { managerAuth: true },
// 			},
// 			{
// 				path: "/restaurantReport",
// 				name: "問卷報表,餐廳實名制疫調問卷",
// 				component: () => import("@/views/back/Report/Restaurantport"),
// 				meta: { managerAuth: true },
// 			},
// 			{
// 				path: "/smokingReport",
// 				name: "問卷報表,吸菸區實名制疫調問卷",
// 				component: () => import("@/views/back/Report/SmokingReport"),
// 				meta: { managerAuth: true },
// 			},

// 			/////系統管理//////////////////////////////////////////////////////
// 			{
// 				path: "system/organization",
// 				name: "系統管理,組織管理",
// 				component: () => import("@/views/SystemManage/Department"),
// 				meta: { managerAuth: true },
// 			},
// 			{
// 				path: "system/features",
// 				name: "系統管理,功能管理",
// 				component: () => import("@/views/SystemManage/Features"),
// 				meta: { managerAuth: true },
// 			},
// 			{
// 				path: "system/role",
// 				name: "系統管理,角色管理",
// 				component: () => import("@/views/SystemManage/Role"),
// 				meta: { managerAuth: true },
// 			},
// 			{
// 				path: "system/menu",
// 				name: "系統管理,選單管理",
// 				component: () => import("@/views/SystemManage/Menu"),
// 				meta: { managerAuth: true },
// 			},
// 			{
// 				path: "system/schedule",
// 				name: "系統管理,排程管理",
// 				component: () => import("@/views/SystemManage/Schedule"),
// 				meta: { managerAuth: true },
// 			},


// 			{
// 				path: "questionnaire_management/footprint_manage",
// 				name: "問卷管理,足跡管理",
// 				component: () => import("@/views/back/FootprintManage/FootprintManage"),
// 				meta: { managerAuth: true },
// 			},
// 		]
// 	},
// 	{
// 		path: "*",
// 		redirect: "/",
// 	},
  
// ];

const routes = [
	{
		path: '/',
		name: 'Precautions',
		component() {
			return import('@/views/Precautions/Precautions.vue');
		},
	},
	{
		path: '/external_precautions',
		name: 'ExternalPrecautions',
		component() {
			return import('@/views/Precautions/ExternalPrecautions.vue');
		},
	},
	// {
	// path: "/",
	// // name: 'Login',
	// component: () => import("@/views/back/Login/ManagerLogin"),
	// },
	{
		path: '/precautions',
		name: 'Precautions',
		component() {
		return import('@/views/Precautions/Precautions.vue');
		},
	},
	// {
	// 	path: '/health',
	// 	name: 'Health',
	// 	component() {
	// 		return import('@/views/Questionnaire/Health.vue');
	// 		},
	// },
	{
		path: '/health',
		name: 'Health',
		component() {
			return import('@/views/Questionnaire/HealthApi.vue');
			},
	},
	{
		path: '/smoking/:id',
		name: 'Smoking',
		component() {
			return import('@/views/Questionnaire/Smoking.vue');
		},
	},
	{
		path: '/restaurant/:id',
		name: 'Restaurant',
		component() {
			return import('@/views/Questionnaire/Restaurant.vue');
		},
	},
	{
		path: '/external_health',
		name: 'ExternalHealth',
		component() {
			return import('@/views/Questionnaire/ExternalHealth.vue');
			},
	},
	{
		path: '/finish',
		name: 'Finish',
		component() {
			return import('@/views/Questionnaire/Finish.vue');
		},
	},
	{
		path: "/login",
		name: 'Login',
		component: () => import("@/views/back/Login/ManagerLogin"),
	},
	{
		path: "/manager",
		component: () => import("@/views/back/Layout/ManagerLayout"),
		meta: { managerAuth: true },
		children: [
			{
				path: "/healthReport",
				name: "問卷報表,健康疫調問卷",
				component: () => import("@/views/back/Report/Report"),
				meta: { managerAuth: true },
			},
			{
				path: "/restaurantReport",
				name: "問卷報表,餐廳實名制疫調問卷",
				component: () => import("@/views/back/Report/Restaurantport"),
				meta: { managerAuth: true },
			},
			{
				path: "/smokingReport",
				name: "問卷報表,吸菸區實名制疫調問卷",
				component: () => import("@/views/back/Report/SmokingReport"),
				meta: { managerAuth: true },
			},
			{
				path: "/externalhealthReport",
				name: "問卷報表,外部健康疫調問卷",
				component: () => import("@/views/back/Report/ExternalHealthReport"),
				meta: { managerAuth: true },
			},
  
			/////系統管理//////////////////////////////////////////////////////
			{
				path: "system/organization",
				name: "系統管理,組織管理",
				component: () => import("@/views/SystemManage/Department"),
				meta: { managerAuth: true },
			},
			{
				path: "system/features",
				name: "系統管理,功能管理",
				component: () => import("@/views/SystemManage/Features"),
				meta: { managerAuth: true },
			},
			{
				path: "system/role",
				name: "系統管理,角色管理",
				component: () => import("@/views/SystemManage/Role"),
				meta: { managerAuth: true },
			},
			{
				path: "system/menu",
				name: "系統管理,選單管理",
				component: () => import("@/views/SystemManage/Menu"),
				meta: { managerAuth: true },
			},
			{
				path: "system/parameter",
				name: "系統管理,參數設定",
				component: () => import("@/views/SystemManage/ParameterSetting"),
				meta: { managerAuth: true },
			},
			{
				path: "system/schedule",
				name: "系統管理,排程管理",
				component: () => import("@/views/SystemManage/Schedule"),
				meta: { managerAuth: true },
			},

			/////問卷管理//////////////////////////////////////////////////////
			{
				path: "questionnaire_management/footprint_manage",
				name: "問卷管理,足跡管理",
				component: () => import("@/views/back/FootprintManage/FootprintManage"),
				meta: { managerAuth: true },
			},
			{
				path: "questionnaire_management/health_manage",
				name: "問卷管理,自主健康管理",
				component: () => import("@/views/back/HealthManage/HealthManage"),
				meta: { managerAuth: true },
			},
			{
				path: "questionnaire_management/health_manage/design",
				name: "問卷管理,自主健康管理設計",
				component: () => import("@/views/back/HealthManage/New"),
				meta: { managerAuth: true },
			},
			{
				path: "questionnaire_management/restaurant_manage",
				name: "問卷管理,餐廳管理",
				component: () => import("@/views/back/RestaurantManage/RestaurantManage"),
				meta: { managerAuth: true },
			},
			{
				path: "questionnaire_management/smoking_manage",
				name: "問卷管理,吸菸管理",
				component: () => import("@/views/back/SmokingManage/SmokingManage"),
				meta: { managerAuth: true },
			},
			{
				path: "questionnaire_management/externalqnr_manage",
				name: "問卷管理,外部疫調管理",
				component: () => import("@/views/back/ExternalHealthQnrManage/ExternalHealthQnrManage"),
				meta: { managerAuth: true },
			},
			{
				path: "questionnaire_management/externalqnr_manage/design",
				name: "問卷管理,外部疫調管理設計",
				component: () => import("@/views/back/ExternalHealthQnrManage/New"),
				meta: { managerAuth: true },
			},

			/////匡列管理//////////////////////////////////////////////////////
			{
				path: "contact_tracing/restaurant_contact",
				name: "匡列管理,餐廳匡列設定",
				component: () => import("@/views/back/ContactTracing/RestaurantContactTracing"),
				meta: { managerAuth: true },
			},
			{
				path: "contact_tracing/smoking_contact",
				name: "匡列管理,吸菸區匡列設定",
				component: () => import("@/views/back/ContactTracing/SmokingContactTracing"),
				meta: { managerAuth: true },
			},

			/////範本維護//////////////////////////////////////////////////////
			{
				path: "template/consent_form",
				name: "範本維護,健康疫調同意書",
				component: () => import("@/views/back/TemplateMaintain/ConsentForm"),
				meta: { managerAuth: true },
			},
			{
				path: "template/external_consent_form",
				name: "範本維護,外部疫調同意書",
				component: () => import("@/views/back/TemplateMaintain/ExternalConsentForm"),
				meta: { managerAuth: true },
			},
		]
	},
	{
		path: "*",
		redirect: "/",
	},
	
  ];

const router = new VueRouter({
	// mode: 'history',
	// base: process.env.BASE_URL,
	routes,
});

export default router;
